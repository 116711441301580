import { Injectable } from '@angular/core';
import { PermissionRequestTypeName } from '@root/app/shared/permits/enums/permission-request-type-name.enum';
import { APP_ROUTES } from '@shared/constant/app-routes';
import { DELEGATION_ROUTE } from '@root/app/delegations/delegation-route';

@Injectable({
  providedIn: 'root',
})
export class AppRouteService {
  static errorPage() {
    return `/${APP_ROUTES.errorPage}`;
  }
  static loginPath() {
    return `/${APP_ROUTES.login}`;
  }

  static userData() {
    return `/${APP_ROUTES.userData}`;
  }

  static homePath() {
    return `/${APP_ROUTES.home}`;
  }

  static unauthorizedPath() {
    return `/${APP_ROUTES.unauthorized}`;
  }

  static forbiddenPath() {
    return `/${APP_ROUTES.forbidden}`;
  }

  static taskManagementListPath() {
    return `/${APP_ROUTES.taskManagement.root.path}/${APP_ROUTES.taskManagement.list.path}`;
  }

  static accessControlManagementPath() {
    return `/${APP_ROUTES.accessControl.root.path}`;
  }
  static securityObservationReportPath() {
    return `/${APP_ROUTES.securityNotes.root.path}/${APP_ROUTES.securityNotes.list.path}`;
  }

  static securityPermitsRoot(): string {
    return `/${APP_ROUTES.securityPermissions.root.path}`;
  }

  static maintenanceDepartmentList(): string {
    return `/${APP_ROUTES.servicesAndRequests.root}/${APP_ROUTES.servicesAndRequests.maintenanceDepartment.root.path}`;
  }

  static securityPermitsListPath(): string {
    return `/${APP_ROUTES.securityPermissions.root.path}/${APP_ROUTES.securityPermissions.list.path}`;
  }
  static maintenanceRequestsListPath(): string {
    return `/${APP_ROUTES.servicesAndRequests.root}/${APP_ROUTES.servicesAndRequests.maintenance.root.path}/${APP_ROUTES.servicesAndRequests.maintenance.list.path}`;
  }
  static maintenanceRequestsEditPath(id: string): string {
    return `/${APP_ROUTES.servicesAndRequests.root}/${APP_ROUTES.servicesAndRequests.maintenance.root.path}/${id}`;
  }
  static maintenanceRequestsAddPath(): string {
    return `/${APP_ROUTES.servicesAndRequests.root}/${APP_ROUTES.servicesAndRequests.maintenance.root.path}`;
  }
  static locationRequestsListPath(): string {
    return `/${APP_ROUTES.servicesAndRequests.root}/${APP_ROUTES.servicesAndRequests.locations.root.path}/${APP_ROUTES.servicesAndRequests.locations.list.path}`;
  }
  static locationRequestDetailsPath(id: number) {
    return `/${APP_ROUTES.servicesAndRequests.root}/${APP_ROUTES.servicesAndRequests.locations.root.path}/${APP_ROUTES.servicesAndRequests.locations.details}/${id}`;
  }
  static locationRequestEditPath(id: number) {
    return `/${APP_ROUTES.servicesAndRequests.root}/${APP_ROUTES.servicesAndRequests.locations.root.path}/${id}`;
  }

  static maintenanceDetailsPath(id: number) {
    return `/${APP_ROUTES.servicesAndRequests.root}/${APP_ROUTES.servicesAndRequests.maintenance.root.path}/${APP_ROUTES.servicesAndRequests.maintenance.details}/${id}`;
  }

  static securityPermitAddPath(type: PermissionRequestTypeName) {
    return `/${APP_ROUTES.securityPermissions.root.path}/${type}`;
  }

  static safesList() {
    return `/${APP_ROUTES.weapons.root.path}/${APP_ROUTES.weapons.safes.root}`;
  }

  static safesEdit(id: number) {
    return `/${APP_ROUTES.weapons.root.path}/${APP_ROUTES.weapons.safes.root}/${id}/${APP_ROUTES.weapons.safes.edit}`;
  }

  static safeDetails(id: number) {
    return `/${APP_ROUTES.weapons.root.path}/${APP_ROUTES.weapons.safes.root}/${id}`;
  }

  static delegationDetail(id: number | string) {
    return `/${APP_ROUTES.delegations.root.path}/${id}`;
  }

  static delegationEdit(id: number | string) {
    return `/${APP_ROUTES.delegations.root.path}/${DELEGATION_ROUTE.edit}/${id}`;
  }

  static newsList() {
    return `/${APP_ROUTES.news.root.path}`;
  }
  static newsDetail(id: number | string) {
    return `/${APP_ROUTES.news.root.path}/${id}`;
  }

  static systemAdminList() {
    return `/${APP_ROUTES.systemAdmin.root.path}`;
  }

  static incidentsAndServices() {
    return `/${APP_ROUTES.scModule.root.path}`;
  }

  static weaponsRoot() {
    return `/${APP_ROUTES.weapons.root.path}`;
  }
  static weaponFormsManagementRoot() {
    return `/${APP_ROUTES.weapons.weaponsFormsManagement.root}`;
  }
}
